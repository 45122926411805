<template>
  <div class="form-item">
    <el-table
        :data="tableData"
        border
        style="width: 100%">
        <el-table-column width='60'>
          <template slot="header">
           <i class="el-icon-circle-plus-outline" @click="addTable"></i>
          </template>
          <template slot-scope="scope">
            {{scope.row.id}}
          </template>
        </el-table-column>
        <el-table-column v-for="(item,ins) of column" :key="ins"
        :prop="item.prop"
        :label="item.label"> 
        <template slot-scope="scope">
             <template >
                 <el-input v-model="scope.row[item.prop]"></el-input>
             </template>
         </template>
        </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
    name:'form-item',
    props:{
        column:Array
    },
    data:()=>{
      return {
          tableData: [
              {
               id:'1',
               key: '',
               value: ''
               }
          ],
          aa:'11'
      }
    },
    methods:{
      addTable(){
          if(this.tableData.length<5){
              this.tableData.push({
                  id:String(this.tableData.length+1),
                  key:'',
                  value:''
              })
          }else{
              this.$message('最多可添加5个标签')
          }
      }
    }
}
</script>
<style scoped lang='scss'>

</style>